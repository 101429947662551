import React from 'react';
import Image from 'next/image';

import useMediaQuery from 'utils/useMediaQuery';
import { Icon } from 'components/tokens/Icon';
import { chevronDown, arrowRight } from 'icons/default';
import { Colors } from 'enums/Colors';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';

import styles from './Hero.module.scss';

interface Props {
  title: string;
  text?: string;
  mobileSrc: string;
  desktopSrc: string;
  alt: string;
  size?: 'small' | 'normal';
  hasChevron?: boolean;
  variant?: 'full-length' | 'half-length';
  heroButtonLink?: string;
  heroButtonText?: string;
  isTopLevel?: boolean;
}

export const Hero: React.FC<Props> = ({
  title,
  text,
  mobileSrc,
  desktopSrc,
  alt,
  size = 'normal',
  hasChevron = false,
  variant = 'half-length',
  children,
  heroButtonLink,
  heroButtonText,
  isTopLevel,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div
      className={`${styles.wrapper} ${styles[size]} ${
        !isMobile && hasChevron ? styles['has-chevron'] : ''
      }`}
    >
      <Image
        layout="fill"
        objectFit="cover"
        src={isMobile ? mobileSrc : desktopSrc}
        alt={alt}
        priority
      />
      <div
        className={`${styles.texts} ${styles[variant]} ${isTopLevel ? styles['top-layer'] : ''}`}
      >
        {title && <h1>{title}</h1>}
        {text && <p>{text}</p>}
        {heroButtonText && heroButtonLink && (
          <div className={styles['hero-button']}>
            <Button
              type={ButtonType.Button}
              trailingIcon={arrowRight}
              size={ButtonSize.Large}
              variant={ButtonVariant.PrimaryMintSweet}
              href={heroButtonLink}
            >
              {heroButtonText}
            </Button>
          </div>
        )}

        {children}
      </div>
      {!isMobile && hasChevron && (
        <span className={styles['arrow-down']}>
          <Icon icon={chevronDown} size={32} color={Colors.White} />
        </span>
      )}
    </div>
  );
};
