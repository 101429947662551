import React from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { ProductList as ProductListView } from 'components/views/ProductList';
import { useVehicleData } from 'lib/api/swr/useVehiclesData';
import { getMarketplaceAdsParams } from 'utils/marketplaceAdsFilter';
import { MarketplaceAdsResponse } from 'types/MarketplaceAdsResponse';
import { FilterProvider } from 'utils/FilterContext';
import { getQueryParams } from 'utils/FilterQueries';

interface IProductList {
  vehiclesData: MarketplaceAdsResponse;
}

const ProductList: NextPage<IProductList> = ({ vehiclesData }) => {
  const { query } = useRouter();
  const params = getMarketplaceAdsParams(getQueryParams(query));
  const { data: clientSideData, isLoading, error } = useVehicleData(params, vehiclesData);
  const router = useRouter();
  const canonicalUrl = `https://${process.env.NEXT_PUBLIC_SITE_DOMAIN}${router.pathname}`;
  return (
    <>
      <Head>
        <title>Köp begagnade bilar, husbilar och MC på dina villkor - Blipp</title>
        <meta
          property="og:title"
          content="Köp begagnade bilar, husbilar och MC på dina villkor - Blipp"
        />
        <meta
          property="og:description"
          content="Alla fordon du köper via Blipp har gått igenom vår 3-stegskontroll och både köpare och säljare är verifierade med bank-id, tryggare än så blir det inte."
        />
        <meta
          name="description"
          content="Alla fordon du köper via Blipp har gått igenom vår 3-stegskontroll och både köpare och säljare är verifierade med bank-id, tryggare än så blir det inte."
        />
        <link rel="canonical" key="canonical" href={canonicalUrl} />
      </Head>
      <FilterProvider>
        <ProductListView apiResponse={clientSideData} isLoading={isLoading} error={error} />
      </FilterProvider>
    </>
  );
};

export default ProductList;
