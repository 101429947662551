import React from 'react';

import { Layout } from 'components/Layout';
import { MarketplaceAdsResponse } from 'types/MarketplaceAdsResponse';
import { ProductCard, ProductCardTheme } from 'components/ProductCard/ProductCard';
import { Hero } from 'components/sections/Hero';
import { carListHero } from 'content/hero';
import { Gutter } from 'components/Gutter';
import { Paginate } from 'components/Paginate';
import { StatusWithIcon } from 'components/StatusWithIcon';
import { StatusWithIconVariant } from 'components/StatusWithIcon/StatusWithIcon';
import { FilterMobile } from 'components/views/ProductList/components/FilterMobile';
import { ControlCard } from 'components/ControlCard';

import { Collections } from './components/Collections';
import styles from './ProductList.module.scss';
import { getCoverPhotoPath, getEquipmentValues, getSavingsValue } from './actions';
import Search from './components/Search/Search';

interface Props {
  apiResponse?: MarketplaceAdsResponse;
  isLoading: boolean;
  error: any;
}

export const ProductList: React.FC<Props> = ({ apiResponse, error, isLoading }) => {
  const hasNoResult = !error && !isLoading && !apiResponse?.payload?.items.length;
  const hasDataResult = !error && !isLoading && apiResponse?.payload?.items.length;
  const carLength = apiResponse?.payload?.total ?? 0;
  let vehicles: JSX.Element[] = [];

  if (hasDataResult) {
    vehicles = apiResponse.payload.items.map((item) => {
      const savings = getSavingsValue(item);
      const equipment = getEquipmentValues(item);
      const cover = getCoverPhotoPath(item);
      const mileage = Number(item.typeform.mileage.values[0].value);

      return (
        <ProductCard
          environmentally_friendly={item.vehicle.environmentally_friendly}
          equipment={equipment}
          fuel={item.vehicle.fuel ?? undefined}
          gearbox={item.vehicle.gearbox ?? undefined}
          id={item.entity_id}
          imageSrc={cover}
          key={item.entity_id}
          km={mileage}
          location={item.municipality?.name ?? ''}
          first_time_in_traffic={item.vehicle.first_time_in_traffic}
          monthly_cost={item.vehicle.monthly_cost.monthly_cost}
          price={item.price ?? 0}
          savings={savings}
          subtitle={`${item.vehicle.trim_package ? `${item.vehicle.trim_package},` : ''} ${
            item.vehicle.drive_wheels_type ? `${item.vehicle.drive_wheels_type},` : ''
          } ${item.vehicle.horsepower ? `${item.vehicle.horsepower} hk` : ''}`}
          title={`${item.vehicle.brand} ${item.vehicle.model_gen_engine ?? item.vehicle.model} ${
            item.vehicle.model_year
          }`}
          vehicleType={item.vehicle.vehicle_type}
          theme={ProductCardTheme.Dark}
          is_company_leasable={item.vehicle.is_company_leasable}
          customCarTitle={item.custom_car_title}
        />
      );
    });
  }

  if (carLength <= 3) {
    vehicles.push(
      <React.Fragment key="control-card">
        <ControlCard />
      </React.Fragment>
    );
  } else {
    vehicles.splice(
      3,
      0,
      <React.Fragment key="control-card">
        <ControlCard />
      </React.Fragment>
    );
  }

  return (
    <Layout>
      <Hero
        title={carListHero.title}
        mobileSrc={carListHero.mobileSrc}
        desktopSrc={carListHero.desktopSrc}
        variant="full-length"
        alt={carListHero.alt}
        isTopLevel
      >
        <Search />

        <Collections />
        <div className={styles['filter-mobile-wrapper']}>
          <FilterMobile />
        </div>
        <div className={styles['number-of-cars']}>
          <span>
            Antal fordon som visas: <span className={styles['car-length']}>{carLength} st</span>
          </span>
        </div>
      </Hero>
      <Gutter size="negative">
        <div className={styles['content-wrapper']}>
          {error ? (
            <StatusWithIcon
              variant={StatusWithIconVariant.Error}
              title="Något gick fel!"
              text="Försök igen eller ladda om sidan genom att"
              link={{ text: 'klicka här.', href: '/fordon' }}
            />
          ) : null}
          {isLoading && !error ? (
            <div className={styles['loading-wrapper']}>
              <video autoPlay loop muted playsInline>
                <source src="/assets/videos/blipp-logotyp-azur-animation.mp4" type="video/mp4" />
              </video>
              Laddar...
            </div>
          ) : null}
          {hasNoResult ? (
            <div className={styles['no-result']}>
              <StatusWithIcon
                variant={StatusWithIconVariant.NoResult}
                title="Inga resultat hittades..."
                text="Prova med en annan filtrering genom att"
                link={{ text: 'klicka här.', href: '/fordon' }}
              />
              <ControlCard />
            </div>
          ) : null}
          {hasDataResult ? (
            <>
              <div className={styles.productList}>{vehicles}</div>
              <div className={styles['paginate-wrapper']}>
                <Paginate
                  itemsTotal={apiResponse.payload.total}
                  itemsPerPage={apiResponse.payload.per_page}
                  currentPage={apiResponse.payload.page}
                  pathname="/fordon"
                />
              </div>
            </>
          ) : null}
        </div>
      </Gutter>
    </Layout>
  );
};
